import React from 'react';

const Main = () => {
  document.title = 'Main';

  return (
    <div className="text-3xl">
      Welcome
    </div>
  );
};


export default Main;
